import React, { useState, useEffect } from 'react';
import axios from 'axios';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import './CustomerForm.css';
import CustomerModal from './CustomerModal';  //Import the modal component

const CustomerForm = () => {
  const [customers, setCustomers] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    fetchCustomers();
  }, []);

  const fetchCustomers = async () => {
    try {
      const response = await axios.get('/api/customers');
      setCustomers(response.data);
    } catch (error) {
      console.error('Error fetching customers:', error);
    }
  };

  const handleExportExcel = () => {
    const ws = XLSX.utils.json_to_sheet(customers);
    const wb = XLSX.utils.book_new();
  
    XLSX.utils.book_append_sheet(wb, ws, 'Customers');
    XLSX.writeFile(wb, 'Customers.xlsx');
  };

  const handleExportPDF = () => {
    const doc = new jsPDF();

    doc.autoTable({ html: '#customerFormTable' });
    doc.save('Customers.pdf');
  };

  const handleplus = () => {
    setIsModalOpen(true);
    
  }

  const filteredCustomers = customers.filter(customer =>
    customer.CustomerName.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="customer-form-container">
      <h2 className="customer-form-title">Customer</h2>
      <div className="customer-top-bar">
        <input
          type="text"
          placeholder="Search by customer name"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="customer-search-box"
        />
        <button className="customer-plus-button" onClick={handleplus} title="Add new customer">+</button>
        <button className="customer-export-button" onClick={handleExportExcel} title="Export to Excel">
  <img src={`${process.env.PUBLIC_URL}/exportxl.png`} alt="Export to Excel" className="export-icon" />

</button>
<button className="customer-export-button" onClick={handleExportPDF} title="Export to PDF">
  <img src={`${process.env.PUBLIC_URL}/exportpdf.png`} alt="Export to PDF" className="export-icon" />
  
</button>

      </div>
      <table id="customerFormTable" className="customer-form-table">
        <thead>
          <tr>
            <th>Customer Name</th>
            <th>Location</th>
            <th>Contact Person</th>
            <th>Mobile</th>
            <th>Email</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {filteredCustomers.map((customer, index) => (
            <tr key={index}>
              <td>{customer.CustomerName}</td>
              <td>{customer.Location}</td>
              <td>{customer.ContactPerson}</td>
              <td>{customer.Mobile}</td>
              <td>{customer.EMailID}</td>
              <td>
                <button className="customer-edit-button" title="Edit">
                  <img src={`${process.env.PUBLIC_URL}/pencil-icon.png`} alt="Edit" className="edit-icon" />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <CustomerModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />{/* Modal component */}
    </div>
  );
};

export default CustomerForm;
