import React, { useState } from 'react';
import './CustomerListModal.css';
import CustomerModal from './CustomerModal';

const CustomerListModal = ({ isOpen, customers, onSelectCustomer, onClose }) => {

  
  const [isCustomerModalOpen, setIsCustomerModalOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  if (!isOpen) return null;

  // Filter customers based on the search term
  const filteredCustomers = customers.filter(customer => {
    const searchLower = searchTerm.toLowerCase();
    return (
      customer.CustomerName.toLowerCase().includes(searchLower) ||
      customer.EMailID.toLowerCase().includes(searchLower)  ||
      customer.ContactPerson.toLowerCase().includes(searchLower) || customer.Mobile.includes(searchTerm) || customer.Location.toLowerCase().includes(searchLower)
    
    );
  });


    // Function to open the modal
    const openCustomerModal = () => {
      setIsCustomerModalOpen(true);
    };
  
    // Function to close the modal
    const closeCustomerModal = () => {
      setIsCustomerModalOpen(false);
    };

  return (
    <div className="customer-modal-overlay">
      <div className="customer-modal">
        {/* Close button at the top-right corner */}
         {/* Button to open the modal */}
      <button className="add-btn" onClick={openCustomerModal}>+</button>
        <button className="close-btn" onClick={onClose}>×</button>
        <h2>Select Customer</h2>
        <input
          type="text"
          placeholder="Search by Name or Email"
          value={searchTerm} // Control input value
          onChange={(e) => setSearchTerm(e.target.value)} // Update search term
        />
        {/* Display filtered customers in a table format */}
        <table className="customer-table">
          <thead>
            <tr>
              <th>Customer Name</th>
              <th>Email</th>
              <th>Mobile</th>
              <th>Contact Person</th>
              <th>Location</th>
              <th>Select</th>
            </tr>
          </thead>
          <tbody>
            {filteredCustomers.map((customer) => (
              <tr key={customer.EMailID} onClick={() => onSelectCustomer(customer.EMailID)}>
                <td>{customer.CustomerName}</td>
                <td>{customer.EMailID}</td>
                <td>{customer.Mobile}</td>
                <td>{customer.ContactPerson}</td>
                <td>{customer.Location}</td>
                <td><button className="select-btn">Select</button></td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {isCustomerModalOpen && (
        <CustomerModal
          isOpen={isCustomerModalOpen}
          onClose={closeCustomerModal}  // Pass the close function to the modal
        />
      )}
    </div>
  );
};

export default CustomerListModal;
