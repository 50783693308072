import React from 'react';
import { Document, Page } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css'; // Ensure this CSS is imported
import './PDFPopup.css'; // Optional: Add custom styles for your popup

const PDFPopup = ({ pdfFile, onClose }) => {
  return (
    <div className="pdf-popup">
      <div className="pdf-popup-content">
        <button className="close-button" onClick={onClose}>Close</button>
        <Document file={pdfFile}>
          <Page pageNumber={1} />
        </Document>
      </div>
    </div>
  );
};

export default PDFPopup;
