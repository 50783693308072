import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import Sidebar from './Sidebar';
import DashboardHeader from './DashboardHeader';
import * as XLSX from 'xlsx'; // Import for Excel export
import './OfficeAssetsPage.css'; // Add styles

const OfficeAssetsPage = () => {
  const navigate = useNavigate(); // Initialize navigate
  const [officeData, setOfficeData] = useState([
    { location: 'Building A', buildings: 3, floor: 5, total: 50, engaged: 30, available: 20 },
    { location: 'Building B', buildings: 2, floor: 4, total: 40, engaged: 25, available: 15 },
    { location: 'Building C', buildings: 4, floor: 6, total: 60, engaged: 40, available: 20 },
  ]);

  const [searchCriteria, setSearchCriteria] = useState({ location: '' });
  const [searchResults, setSearchResults] = useState([]);
  const [showSearchPopup, setShowSearchPopup] = useState(false);

  const handleSearch = () => {
    const results = officeData.filter(office =>
      office.location.toLowerCase().includes(searchCriteria.location.toLowerCase())
    );
    setSearchResults(results);
    setShowSearchPopup(false);
  };

  const handleExportToExcel = () => {
    const ws = XLSX.utils.json_to_sheet(officeData); // Convert office data to sheet
    const wb = XLSX.utils.book_new(); // Create a new workbook
    XLSX.utils.book_append_sheet(wb, ws, 'OfficeAssets'); // Append the sheet to the workbook
    XLSX.writeFile(wb, 'OfficeAssetsData.xlsx'); // Download the file
  };

  // Function to navigate to office details
  const handleRowClick = (location) => {
    navigate('/office-details', { state: { location } }); // Navigate and pass state
  };

  return (
    <div className="office-assets-page-container">
      <div className="main-content">
        <h2>Office Assets</h2>

        <div className="export-buttons">
          <button onClick={() => setShowSearchPopup(true)} className="export-button">
            <img src="https://e1.pngegg.com/pngimages/552/897/png-clipart-bundle-icon-search-blue-search-button-thumbnail.png" alt="Search" className="icon" />
          </button>

          <button onClick={handleExportToExcel} className="export-button">
            <img src="https://upload.wikimedia.org/wikipedia/commons/7/73/Microsoft_Excel_2013-2019_logo.svg" alt="Export to Excel" className="icon" />
          </button>
        </div>

        <table>
          <thead>
            <tr>
              <th>Location</th>
              <th>Buildings</th>
              <th>Floor</th>
              <th>Total</th>
              <th>Engaged</th>
              <th>Available</th>
            </tr>
          </thead>
          <tbody>
            {searchResults.length > 0
              ? searchResults.map((office, index) => (
                  <tr key={index} onClick={() => handleRowClick(office.location)} style={{ cursor: 'pointer' }}>
                    <td>{office.location}</td>
                    <td>{office.buildings}</td>
                    <td>{office.floor}</td>
                    <td>{office.total}</td>
                    <td>{office.engaged}</td>
                    <td>{office.available}</td>
                  </tr>
                ))
              : officeData.map((office, index) => (
                  <tr key={index} onClick={() => handleRowClick(office.location)} style={{ cursor: 'pointer' }}>
                    <td>{office.location}</td>
                    <td>{office.buildings}</td>
                    <td>{office.floor}</td>
                    <td>{office.total}</td>
                    <td>{office.engaged}</td>
                    <td>{office.available}</td>
                  </tr>
                ))}
          </tbody>
        </table>

        {showSearchPopup && (
          <div className="edit-popup">
            <h4>Search Offices</h4>
            <input
              type="text"
              value={searchCriteria.location}
              onChange={e => setSearchCriteria({ ...searchCriteria, location: e.target.value })}
              placeholder="Enter office location"
            />
            <div>
              <button onClick={handleSearch}>Search</button>
              <button onClick={() => setShowSearchPopup(false)}>Cancel</button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default OfficeAssetsPage;
