import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import './Sidebar.css';

const Sidebar = () => {
  const [menus, setMenus] = useState([]);
  const [openSubmenus, setOpenSubmenus] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [collapsed, setCollapsed] = useState(false);

  // Fetch the menu list from the server when the component loads
  useEffect(() => {
    const fetchMenus = async () => {
      try {
        const response = await fetch('/api/menus', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({}),
        });

        if (!response.ok) {
          throw new Error('Failed to fetch menus');
        }

        const data = await response.json();
        setMenus(data);
      } catch (error) {
        console.error('Error fetching menus:', error);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchMenus();
  }, []);

  // Toggle submenu visibility
  const toggleSubmenu = (menuCode) => {
    setOpenSubmenus((prevState) => ({
      ...prevState,
      [menuCode]: !prevState[menuCode],
    }));
  };

  // Toggle sidebar collapse
  const toggleSidebar = () => {
    setCollapsed((prev) => !prev);
  };

  if (loading) {
    return <div>Loading menus...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className={`sidebar ${collapsed ? 'collapsed' : ''}`}>
      <ul>
        {menus.map((menu) => (
          <li key={menu.MenuCode}>
            {menu.FrmName === 'Dashboard' ? (
              // Direct link for the main menu item "Dashboard"
              <Link to="/dashboard" className="menu-item">
                <img
                  src={`${process.env.PUBLIC_URL}/${menu.MenuName}.png`}
                  alt={`${menu.MenuName} icon`}
                  className="menu-icon"
                />
                {!collapsed && <span>{menu.MenuName}</span>}
              </Link>
            ) : (
              <div onClick={() => toggleSubmenu(menu.MenuCode)} className="submenu-toggle">
                <img
                  src={`${process.env.PUBLIC_URL}/${menu.MenuName}.png`}
                  alt={`${menu.MenuName} icon`}
                  className="menu-icon"
                />
                {!collapsed && <span>{menu.MenuName}</span>}
                {menu.subMenus.length > 0 && !collapsed && (
                  <FontAwesomeIcon
                    icon={openSubmenus[menu.MenuCode] ? faChevronDown : faChevronRight}
                    className="submenu-icon"
                  />
                )}
              </div>
            )}
            {openSubmenus[menu.MenuCode] && menu.subMenus.length > 0 && !collapsed && (
              <ul className="submenu">
                {menu.subMenus.map((subMenu) => (
                  <li key={subMenu.MenuCode}>
                    <Link
                      to={
                        subMenu.FrmName === 'Customer' ? '/customer-form' :
                        subMenu.FrmName === 'Office' ? '/office-form' :
                        subMenu.FrmName === 'ConferenceRooms' ? '/conference-rooms' :
                        subMenu.FrmName === 'Assets' ? '/assets' :
                        subMenu.FrmName === 'Employee' ? '/employees' :
                        subMenu.FrmName === 'Visitor' ? '/visitor-management' :
                        subMenu.FrmName === 'Transaction' ? '/transaction' :
                        subMenu.FrmName === 'Contract' ? '/contract' :
                        subMenu.FrmName === 'Invoicing' ? '/invoicing' :
                        subMenu.FrmName === 'Payment' ? '/payment' :
                        subMenu.FrmName === 'Support' ? '/support' :
                        subMenu.FrmName === 'Booking' ? '/booking' :
                        `/${subMenu.FrmName}`
                      }
                    >
                      {subMenu.MenuName}
                    </Link>
                  </li>
                ))}
              </ul>
            )}
          </li>
        ))}
      </ul>
      <div className="toggle-arrow" onClick={toggleSidebar}>
        <FontAwesomeIcon icon={collapsed ? faChevronRight : faChevronDown} />
      </div>
    </div>
  );
};

export default Sidebar;
