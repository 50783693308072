import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import CustomerModal from './CustomerModal'; // Adjust the import path as necessary
import './DashboardHeader.css';

const DashboardHeader = () => {
  const [showProfileDropdown, setShowProfileDropdown] = useState(false);
  const [profileData, setProfileData] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false); // State to control modal visibility
  const [loading, setLoading] = useState(true); // Loading state
  const [error, setError] = useState(null); // State for error messages
  const profileRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProfileData = async () => {
      setLoading(true);
      setError(null);
      try {
        const token = sessionStorage.getItem('token'); // Get the token from session storage
        if (!token) {
          setError('Token not found. Please log in.');
          setLoading(false);
          return;
        }
        
        const response = await fetch('/api/user-profile', {
          headers: {
            'Authorization': `Bearer ${token}` // Include the token in the headers
          }
        });
        
        if (response.ok) {
          const data = await response.json();
          setProfileData(data.userProfile); // Use the userProfile field from the API response
        } else {
          const errorData = await response.json();
          setError(errorData.message || 'Failed to fetch profile data');
        }
      } catch (error) {
        setError('An error occurred while fetching profile data.');
      } finally {
        setLoading(false);
      }
    };

    fetchProfileData();
  }, []);

  const handleLogout = () => {
     sessionStorage.removeItem('token'); // Clear the token on logout
     navigate('/'); // Redirect to login page
  };

  const toggleProfileDropdown = () => {
    setShowProfileDropdown((prev) => !prev);
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
    setShowProfileDropdown(false); // Close dropdown when opening modal
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (profileRef.current && !profileRef.current.contains(event.target)) {
        setShowProfileDropdown(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [profileRef]);

  const profileImageUrl = "https://t4.ftcdn.net/jpg/04/75/00/99/360_F_475009987_zwsk4c77x3cTpcI3W1C1LU4pOSyPKaqi.jpg";

  
  useEffect(() => {
    let timer;

    const handleIdle = () => {
      console.log('User is idle. Logging out...');
      sessionStorage.removeItem('Token'); // Clear the token or session storage
      navigate('/login'); // Redirect to login page
    };

    const resetTimer = () => {
      clearTimeout(timer);
      timer = setTimeout(handleIdle, 30000000); // 30 seconds
    };

    // Set up event listeners for user activity
    const events = ['mousemove', 'keydown', 'click', 'scroll'];

    // Attach event listeners to reset the idle timer on activity
    events.forEach(event => {
      window.addEventListener(event, resetTimer);
    });
    

    resetTimer(); // Start the timer

    // Cleanup function to clear timeout and remove event listeners
    return () => {
      clearTimeout(timer);
      events.forEach(event => {
        window.removeEventListener(event, resetTimer);
      });
    };
  }, [navigate]); //


  return (
    <header className="dashboard-header">
      <div className="logo-container">
        <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRsMr9ft0oEecax-pXxXTiOBhiIS4UOp9GB2A&s" alt="Logo" className="logo" />
        <span className="company-name">InsightSpace</span>
      </div>
      <div className="profile-container" ref={profileRef}>
        <img 
          src={profileImageUrl} 
          alt="Profile" 
          className="profile-image" 
          onClick={toggleProfileDropdown} 
        />
        {showProfileDropdown && profileData && (
          <div className="profile-dropdown">
            <p className="profile-sucode">{profileData.SUcode}</p> {/* Display SUcode here */}
            <p className="profile-name">{profileData.UserName}</p>
            <p className="profile-email">{profileData.EmailID}</p>
            <p className="profile-mobile">{profileData.Mobile}</p>
            {profileData.BPName && (
              <p className="profile-bpname">{profileData.BPName}</p>
            )}
            <button className="logout-button" onClick={handleLogout}>
              Logout
            </button>
          </div>
        )}
        {loading && <p className="loading-message">Loading profile...</p>}
        {error && <p className="error-message">{error}</p>}
      </div>
      {isModalOpen && (
        <CustomerModal 
          isOpen={isModalOpen} 
          onClose={() => setIsModalOpen(false)} 
          createdBy={profileData?.EmailID} // Pass the EmailID to CustomerModal
        />
      )}
    </header>
  );
};

export default DashboardHeader;
