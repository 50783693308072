import React, { useState } from 'react';
import Sidebar from './Sidebar'; // Import your existing Sidebar component
import DashboardHeader from './DashboardHeader'; // Import your existing Header component

const FloorMap = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedSection, setSelectedSection] = useState(null);

  // Define the sections divided into 4 equal parts (squares)
  const sections = [
    { id: 1, name: 'Section 1', top: '20%', left: '30%', width: '50%', height: '50%', info: 'Details about Section 1' },
    { id: 2, name: 'Section 2', top: '20%', left: '50%', width: '50%', height: '50%', info: 'Details about Section 2' },
    { id: 3, name: 'Section 3', top: '50%', left: '30%', width: '50%', height: '50%', info: 'Details about Section 3' },
    { id: 4, name: 'Section 4', top: '50%', left: '50%', width: '50%', height: '50%', info: 'Details about Section 4' },
  ];

  const openModal = (section) => {
    setSelectedSection(section);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setSelectedSection(null);
  };

  return (
    <div style={{ display: 'flex', height: '100vh' }}>
      {/* Sidebar */}
      <div style={{ width: '0%', zIndex: 2 }}> {/* Sidebar Container */}
  <Sidebar />
</div>


      {/* Main content */}
      <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
        {/* Dashboard Header */}
     
      

        {/* PDF Viewer */}
        <div style={{ flex: 1, position: 'relative', padding: '0px', overflow: 'hidden' }}>
          <iframe
            src="/ASBUILT_COSPACIO_8TH_FLOOR.pdf"
            title="PDF Viewer"
            style={{
              position: 'absolute',
              top: '8%', // Shift down by 10%
              left: '0%', // Shift right by 20%
              width: '100%', // 10% less wide
              height: '90%', // 10% less in height
              border: 'none'
            }}
          />
          {sections.map((section) => (
            <div
              key={section.id}
              onClick={() => openModal(section)}
              style={{
                position: 'absolute',
                top: section.top,
                left: section.left,
                width: section.width,
                height: section.height,
                cursor: 'pointer',
                backgroundColor: 'rgba(255, 255, 255, 0.0)', // Fully transparent background
                border: '2px solid rgba(0, 0, 0, 0.0)', // Optional transparent border
              }}
            />
          ))}
        </div>

        {/* Modal for section details */}
        {modalIsOpen && selectedSection && (
          <div style={{ position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', padding: '20px', backgroundColor: 'white', borderRadius: '8px', boxShadow: '0 4px 20px rgba(0, 0, 0, 0.5)' }}>
            <h2>{selectedSection.name}</h2>
            <p>{selectedSection.info}</p>
            <button onClick={closeModal} style={{ backgroundColor: '#f44336', color: 'white', border: 'none', padding: '10px 15px', borderRadius: '5px', cursor: 'pointer' }}>
              Close
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default FloorMap;
