import React, { useState, useEffect } from 'react';

const FClickFloor = () => {
  const [properties, setProperties] = useState([]);
  const [roomNumber, setRoomNumber] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const [editingId, setEditingId] = useState(null);

  // Fetch rooms from the backend when the component mounts
  useEffect(() => {
    const fetchRooms = async () => {
      try {
        const response = await fetch('/api/rooms');
        const data = await response.json();
        setProperties(data);
      } catch (error) {
        console.error('Error fetching rooms:', error);
      }
    };

    fetchRooms();
  }, []);

  const handleMapClick = async (event) => {
    const { clientX, clientY } = event;

    if (isEditing) {
      try {
        await fetch(`/api/rooms/${editingId}`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            roomNumber,
            position: { x: clientX, y: clientY },
          }),
        });

        setProperties(properties.map(property =>
          property.id === editingId ? { ...property, roomNumber, position: { x: clientX, y: clientY } } : property
        ));
        setIsEditing(false);
        setEditingId(null);
        setRoomNumber('');
      } catch (error) {
        console.error('Error updating room:', error);
      }
    } else {
      try {
        const response = await fetch('/api/rooms', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            roomNumber: roomNumber || 'Room ' + (properties.length + 1),
            position: { x: clientX, y: clientY },
          }),
        });

        const newRoom = await response.json();
        setProperties([...properties, { id: newRoom.id, roomNumber, position: { x: clientX, y: clientY } }]);
        setRoomNumber('');
      } catch (error) {
        console.error('Error adding room:', error);
      }
    }
  };

  const handleRoomClick = (id) => {
    const roomToEdit = properties.find(property => property.id === id);
    setRoomNumber(roomToEdit.roomNumber);
    setIsEditing(true);
    setEditingId(id);
  };

  const handleDelete = async (id) => {
    try {
      await fetch(`/api/rooms/${id}`, {
        method: 'DELETE',
      });
      setProperties(properties.filter(property => property.id !== id));
    } catch (error) {
      console.error('Error deleting room:', error);
    }
  };

  return (
    <div>
      <input
        type="text"
        value={roomNumber}
        onChange={(e) => setRoomNumber(e.target.value)}
        placeholder="Enter Room Number"
        style={{ marginBottom: '10px' }}
      />
      <div
        style={{
          width: '1200px',
          height: '800px',
          background: `url('/floormap.jpg') no-repeat center/cover`,
          position: 'relative',
          cursor: 'pointer',
        }}
        onClick={handleMapClick}
      >
        {properties.map(property => (
          <div
            key={property.id}
            onClick={(e) => {
              e.stopPropagation();
              handleRoomClick(property.id);
            }}
            style={{
              position: 'absolute',
              left: property.position.x || 0,
              top: property.position.y || 0,
              transform: 'translate(0%, 0%)',
              background: 'rgba(255, 255, 255, 0.9)',
              border: '2px solid #000',
              borderRadius: '5px',
              padding: '5px',
              cursor: 'pointer',
            }}
          >
            <span>Room: {property.roomNumber}</span>
            <button
              onClick={(e) => {
                e.stopPropagation();
                handleDelete(property.id);
              }}
              style={{ marginLeft: '5px', background: 'red', color: 'white', border: 'none', borderRadius: '3px', cursor: 'pointer' }}
            >
              Delete(X)
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FClickFloor;
