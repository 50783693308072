import React, { useState } from 'react';
import { CSVLink } from 'react-csv';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import Sidebar from './Sidebar';
import * as XLSX from 'xlsx';
import './Contract.css';
import DashboardHeader from './DashboardHeader';

const Contract = () => {
  const [contracts, setContracts] = useState([
    { id: 1, name: 'Contract with company A', date: '2024-01-01', status: 'Active' },
    { id: 2, name: 'Contract with company B', date: '2024-02-15', status: 'Inactive' },
  ]);

  const [newContractName, setNewContractName] = useState('');
  const [newContractDate, setNewContractDate] = useState('');
  const [newContractStatus, setNewContractStatus] = useState('Active');
  const [showAddContract, setShowAddContract] = useState(false);
  const [showEditPopup, setShowEditPopup] = useState(false);
  const [showSearchPopup, setShowSearchPopup] = useState(false);
  const [editContract, setEditContract] = useState(null);
  const [searchCriteria, setSearchCriteria] = useState({ name: '', date: '' });
  const [searchResults, setSearchResults] = useState([]);

  const handleAddContract = () => {
    if (newContractName && newContractDate && newContractStatus) {
      const newContract = {
        id: contracts.length + 1,
        name: newContractName,
        date: newContractDate,
        status: newContractStatus,
      };
      setContracts([...contracts, newContract]);
      setNewContractName('');
      setNewContractDate('');
      setNewContractStatus('Active');
      setShowAddContract(false);
    }
  };

  const handleDeleteContract = (id) => {
    setContracts(contracts.filter(contract => contract.id !== id));
    if (showEditPopup) {
      setShowEditPopup(false);
    }
  };

  const handleExportPDF = () => {
    const doc = new jsPDF();
    doc.autoTable({ html: '#contractsTable' });
    doc.save('contracts.pdf');
  };

  const handleExportExcel = () => {
    const ws = XLSX.utils.json_to_sheet(contracts);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Contracts');
    XLSX.writeFile(wb, 'contracts.xlsx');
  };

  const handleEditContract = (contract) => {
    setEditContract(contract);
    setShowEditPopup(true);
  };

  const handleUpdateContract = () => {
    setContracts(contracts.map(contract => (contract.id === editContract.id ? editContract : contract)));
    setShowEditPopup(false);
    setEditContract(null);
  };

  const handleSearchContracts = () => {
    const results = contracts.filter(contract => {
      return (
        (searchCriteria.name ? contract.name.toLowerCase().includes(searchCriteria.name.toLowerCase()) : true) &&
        (searchCriteria.date ? contract.date === searchCriteria.date : true)
      );
    });
    setSearchResults(results);
    setShowSearchPopup(false);
  };

  return (
    <div className="contract">
  

      <div className="contract-content">
        <h3>/Transaction/Contract</h3>
        <h2>Contracts</h2>

        <div className="export-buttons">
          <button onClick={handleExportPDF} className="export-button" title="Export as PDF">
            📄
          </button>
          <button onClick={handleExportExcel} className="export-button" title="Export as Excel">
            <img src="https://upload.wikimedia.org/wikipedia/commons/7/73/Microsoft_Excel_2013-2019_logo.svg" alt="Excel" className="icon" />
          </button>
          <button onClick={() => setShowAddContract(!showAddContract)} className="export-button add-button" title="Add new data">
            ➕
          </button>
          <button onClick={() => setShowSearchPopup(true)} className="export-button" title="Search data">
            <img src="https://e1.pngegg.com/pngimages/552/897/png-clipart-bundle-icon-search-blue-search-button-thumbnail.png" alt="Search" className="icon" />
          </button>
        </div>

        {showAddContract && (
          <div className="edit-popup">
            <h4>Add New Contract</h4>
            <input
              type="text"
              value={newContractName}
              onChange={(e) => setNewContractName(e.target.value)}
              placeholder="Enter contract name"
            />
            <input
              type="date"
              value={newContractDate}
              onChange={(e) => setNewContractDate(e.target.value)}
              placeholder="Enter last date of contract"
            />
            <input
              type="text"
              value={newContractStatus}
              onChange={(e) => setNewContractStatus(e.target.value)}
              placeholder="Enter contract status"
            />
            <div>
              <button onClick={handleAddContract}>Submit</button>
              <button onClick={() => setShowAddContract(false)}>Cancel</button>
            </div>
          </div>
        )}

        <table id="contractsTable">
          <thead>
            <tr>
              <th>ID</th>
              <th>Name</th>
              <th>Until</th>
              <th>Status</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {contracts.map((contract) => (
              <tr key={contract.id}>
                <td>{contract.id}</td>
                <td>{contract.name}</td>
                <td>{contract.date}</td>
                <td>{contract.status}</td>
                <td>
                  <button onClick={() => handleEditContract(contract)} title="Edit contract">
                    <img src="https://freesvg.org/img/edit-blue.png" alt="Edit" className="icon" />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        {showEditPopup && editContract && (
          <div className="edit-popup">
            <h4>Edit Contract</h4>
            <input
              type="text"
              value={editContract.name}
              onChange={(e) => setEditContract({ ...editContract, name: e.target.value })}
              placeholder="Edit contract name"
            />
            <input
              type="date"
              value={editContract.date}
              onChange={(e) => setEditContract({ ...editContract, date: e.target.value })}
              placeholder="Edit contract last date"
            />
            <input
              type="text"
              value={editContract.status}
              onChange={(e) => setEditContract({ ...editContract, status: e.target.value })}
              placeholder="Edit contract status"
            />
            <div>
              <button onClick={handleUpdateContract}>Update</button>
              <button onClick={() => setShowEditPopup(false)}>Cancel</button>
              <button onClick={() => handleDeleteContract(editContract.id)} title="Delete contract">
                <img src="https://cdn-icons-png.flaticon.com/512/6861/6861362.png" alt="Delete" className="icon" />
              </button>
            </div>
          </div>
        )}

        {showSearchPopup && (
          <div className="edit-popup">
            <h4>Search Contracts</h4>
            <input
              type="text"
              value={searchCriteria.name}
              onChange={(e) => setSearchCriteria({ ...searchCriteria, name: e.target.value })}
              placeholder="Enter contract name"
            />
            <input
              type="date"
              value={searchCriteria.date}
              onChange={(e) => setSearchCriteria({ ...searchCriteria, date: e.target.value })}
            />
            <div>
              <button onClick={handleSearchContracts}>Search</button>
              <button onClick={() => setShowSearchPopup(false)}>Cancel</button>
            </div>
          </div>
        )}

        {searchResults.length > 0 && (
          <div>
            <h3>Search Results</h3>
            <table>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Name</th>
                  <th>Date</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {searchResults.map((contract) => (
                  <tr key={contract.id}>
                    <td>{contract.id}</td>
                    <td>{contract.name}</td>
                    <td>{contract.date}</td>
                    <td>{contract.status}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
};

export default Contract;
