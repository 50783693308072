import React, { useState, useEffect } from 'react';
import './RoomDataPopup.css'; // Assuming you save your CSS styles in this file

import { useHref, useNavigate } from 'react-router-dom'; // Import useNavigate

const RoomDataPopup = ({ onClose }) => {
    const [officeData, setOfficeData] = useState([]);
    const [selectedLocation, setSelectedLocation] = useState(null);
    const [selectedBuilding, setSelectedBuilding] = useState(null); // New state for building
    const [selectedFloor, setSelectedFloor] = useState(null); // New state for floor
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate(); // Initialize navigate from useNavigate

    // Fetch office data from the API
    const fetchOfficeData = async (location = null, building = null, floor = null) => {
        setLoading(true);
        setError(null);

        try {
            const response = await fetch(`/api/office-data${location ? `?location=${encodeURIComponent(location)}&building=${encodeURIComponent(building)}&floor=${encodeURIComponent(floor)}` : ''}`);
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            const data = await response.json();
            setOfficeData(data);
        } catch (error) {
            console.error('Error fetching office data:', error);
            setError('Failed to load office data.');
        } finally {
            setLoading(false);
        }
    };

    // Fetch general office data on mount
    useEffect(() => {
        fetchOfficeData();
    }, []);

    

    // Handle location click event
    const handleLocationClick = (location, building, floor) => {
        setSelectedLocation(location);
        setSelectedBuilding(building); // Set selected building
        setSelectedFloor(floor); // Set selected floor
        fetchOfficeData(location, building, floor);  // Fetch details for the selected location, building, and floor
    };

    // Handle navigate to FloorMap
    const handleFloorMapNavigation = () => {
        navigate('/Click-floor'); // Change this to the correct path for your FloorMap component
    };

    return (
        <div className="popup">
            <h2>Office Statistics</h2>
            <button className="popup-close-btn" onClick={onClose} aria-label="Close">❌</button>

            {loading && <p>
                <div className="dot-spinner">
                    <div className="dot-spinner__dot"></div>
                    <div className="dot-spinner__dot"></div>
                    <div className="dot-spinner__dot"></div>
                    <div className="dot-spinner__dot"></div>
                    <div className="dot-spinner__dot"></div>
                    <div className="dot-spinner__dot"></div>
                    <div className="dot-spinner__dot"></div>
                    <div className="dot-spinner__dot"></div>
                </div>
            </p>}
            {error && <p>{error}</p>}

            {/* Display summary or location details based on selectedLocation */}
            {!loading && !error && (
                <>
                    {selectedLocation ? (
                        <div>
                            <h3>Details for {selectedLocation} / {selectedBuilding} / {selectedFloor}</h3>
                            <table>
                                <thead>
                                    <tr>
                                        <th>Office Number</th>
                                        <th>Office Type</th>
                                        <th>Office Zone</th>
                                        <th>Seats</th>
                                        <th>Window</th>
                                        <th>Status </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {officeData.map((office, index) => (
                                        <tr key={index}>
                                            <td>{office.OfficeNo}</td>
                                            <td>{office.OfficeType}</td>
                                            <td>{office.OfficeZone}</td>
                                            <td>{office.Seats}</td>
                                            <td>{office.Window ? 'Yes' : 'No'}</td>
                                            <td>{office.Status}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    ) : (
                        <table>
                            <thead>
                                <tr>
                                    <th>Location</th>
                                    <th>Building</th>
                                    <th>Floor</th>
                                    <th>Total Offices</th>
                                    <th>Vacant Offices</th>
                                    <th>Occupied Offices</th>
                                    <th>Actions</th> {/* Add Actions header */}
                                </tr>
                            </thead>
                            <tbody>
                                {officeData.map((locationData, index) => (
                                    <tr key={index} onClick={() => handleLocationClick(locationData.Location, locationData.Building, locationData.Floor)}>
                                        <td>{locationData.Location}</td>
                                        <td>{locationData.Building}</td>
                                        <td>{locationData.Floor}</td>
                                        <td>{locationData.TotalOffices}</td>
                                        <td>{locationData.vacantOffices}</td>
                                        <td>{locationData.occupiedOffices}</td>
                                        <td>
                                            {/* Button to navigate to FloorMap */}
                                            <button onClick={handleFloorMapNavigation} style={{ backgroundColor: '#4CAF50', color: 'white', border: 'none', padding: '5px 10px', borderRadius: '5px', cursor: 'pointer' }}>
                                                View Floor Map
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    )}
                </>
            )}
        </div>
    );
};

export default RoomDataPopup;
