import React, { useState } from 'react';

const VacantFormPopup = ({ handleClose }) => {
  const [officeCode, setOfficeCode] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Office Code:', officeCode);
    // Submit the new office data to the backend here
    handleClose(); // Close the form after submission
  };

  return (
    <div className="popup">
      <div className="popup-content">
        <h3>Add New Office</h3>
        <form onSubmit={handleSubmit}>
          <label>
            Office Code:
            <input
              type="text"
              value={officeCode}
              onChange={(e) => setOfficeCode(e.target.value)}
            />
          </label>
          <button type="submit">Submit</button>
        </form>
        <button onClick={handleClose}>Close</button>
      </div>
    </div>
  );
};

export default VacantFormPopup;
