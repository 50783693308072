import React from 'react';
import Sidebar from './Sidebar'; // Import your existing Sidebar component
import DashboardHeader from './DashboardHeader'; // Import your existing Header component

const PdfViewer = () => {
  return (
    <div style={{ display: 'flex', height: '100vh' }}>
      <Sidebar />
      <div style={{ flex: 1, padding: '20px' }}>
        <DashboardHeader />
        <div style={{ height: 'calc(100% - 80px)', overflow: 'auto' }}>
          <iframe
            src="/ASBUILT_COSPACIO_8TH_FLOOR.pdf"
            title="PDF Viewer"
            style={{ width: '100%', height: '100%', border: 'none' }}
          />
        </div>
      </div>
    </div>
  );
};

export default PdfViewer;
