import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FaChevronDown, FaPlus, FaTimes } from 'react-icons/fa';
import SummaryModal from './SummaryModal'; // Import the SummaryModal component

function CabinDetails() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [cabins, setCabins] = useState([]);
  const [filteredCabins, setFilteredCabins] = useState([]);
  const [selectedCabins, setSelectedCabins] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [showPopup, setShowPopup] = useState(false);
  const [showSummaryModal, setShowSummaryModal] = useState(false);

  useEffect(() => {
    axios.get('/api/office-data1')
      .then(response => {
        setCabins(response.data);
        setFilteredCabins(response.data);
      })
      .catch(error => console.error('Error fetching cabin data:', error));
  }, []);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    const filtered = cabins.filter(cabin =>
      (cabin.OfficeNo && cabin.OfficeNo.toLowerCase().includes(e.target.value.toLowerCase())) ||
      (cabin.Seats && cabin.Seats.toString().toLowerCase().includes(e.target.value.toLowerCase())) ||
      (cabin.Window && cabin.Window.toLowerCase().includes(e.target.value.toLowerCase())) ||
      (cabin.Location && cabin.Location.toLowerCase().includes(e.target.value.toLowerCase())) ||
      (cabin.Building && cabin.Building.toLowerCase().includes(e.target.value.toLowerCase())) ||
      (cabin.Floor && cabin.Floor.toLowerCase().includes(e.target.value.toLowerCase())) ||
      (cabin.OfficeType && cabin.OfficeType.toLowerCase().includes(e.target.value.toLowerCase())) ||
      (cabin.OfficeZone && cabin.OfficeZone.toLowerCase().includes(e.target.value.toLowerCase())) ||
      (cabin.Rate && cabin.Rate.toString().toLowerCase().includes(e.target.value.toLowerCase())) ||
      (cabin.DiscountType && cabin.DiscountType.toLowerCase().includes(e.target.value.toLowerCase()))
    );
    setFilteredCabins(filtered);
  };

  const handleRowClick = (cabin) => {
    const isAlreadySelected = selectedCabins.some(selected => selected.OfficeNo === cabin.OfficeNo);
    if (!isAlreadySelected) {
      setSelectedCabins(prev => [
        ...prev,
        {
          OfficeNo: cabin.OfficeNo,
          Seats: cabin.Seats,
          Rate: cabin.Rate || 0,
          DiscountType: '%', 
          DiscountValue: 0,
          DiscountRate: 0,
          FinalRate: cabin.Rate || 0
        }
      ]);
      setShowPopup(false);
    }
  };

  const handleRemoveCabin = (OfficeNo) => {
    setSelectedCabins(prev => prev.filter(cabin => cabin.OfficeNo !== OfficeNo));
  };

  const handleDiscountChange = (index, type, value) => {
    setSelectedCabins(prevCabins => {
      const updatedCabins = [...prevCabins];
      const cabin = updatedCabins[index];
      cabin[type] = type === 'DiscountType' ? value : parseFloat(value) || 0;

      if (cabin.DiscountType === '%') {
        cabin.DiscountRate = cabin.Rate * (cabin.DiscountValue / 100);
      } else {
        cabin.DiscountRate = cabin.DiscountValue || 0;
      }

      cabin.FinalRate = cabin.Rate - cabin.DiscountRate;

      return updatedCabins;
    });
  };

  const handleSummaryClick = () => {
    console.log("Selected Cabins:", selectedCabins);
    setShowSummaryModal(true);
  };
  

  return (
    <section className="section section4">
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <button
          onClick={() => setShowPopup(true)}
          style={{ backgroundColor: '#0000ff', color: 'white', padding: '8px 15px', borderRadius: '4px', cursor: 'pointer', fontWeight: 'bold' }}
        >
          <FaPlus /> Add Cabin
        </button>
      </div>

      <table style={{ width: '100%', margin: '20px 0' }}>
        <thead>
          <tr>
            <th>Cabin Number</th>
            <th>No of Seats</th>
            <th>Rate</th>
            <th>Discount Type</th>
            <th>Discount Value</th>
            <th>Discount Rate</th>
            <th>Final Rate</th>
            <th>Remove</th>
          </tr>
        </thead>
        <tbody>
          {selectedCabins.map((cabin, index) => (
            <tr key={cabin.OfficeNo}>
              <td>{cabin.OfficeNo}</td>
              <td>{cabin.Seats}</td>
              <td>
                <input
                  type="number"
                  placeholder="Rate"
                  value={cabin.Rate}
                  onChange={(e) => handleDiscountChange(index, 'Rate', e.target.value)}
                  style={{ width: '80px' }}
                />
              </td>
              <td>
                <select
                  value={cabin.DiscountType}
                  onChange={(e) => handleDiscountChange(index, 'DiscountType', e.target.value)}
                  style={{ width: '80px' }}
                >
                  <option value="Rs">Rs</option>
                  <option value="%">%</option>
                </select>
              </td>
              <td>
                <input
                  type="number"
                  placeholder={cabin.DiscountType === '%' ? 'Discount %' : 'Discount Rs'}
                  value={cabin.DiscountValue}
                  onChange={(e) => handleDiscountChange(index, 'DiscountValue', e.target.value)}
                  style={{ width: '60px' }}
                />
              </td>
              <td>{cabin.DiscountRate.toFixed(2)}</td>
              <td>{cabin.FinalRate.toFixed(2)}</td>
              <td>
                <button onClick={() => handleRemoveCabin(cabin.OfficeNo)} style={{ color: 'red', cursor: 'pointer' }}>
                  <FaTimes />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {showPopup && (
        <div className="popup">
          <div className="popup-inner">
            <button onClick={() => setShowPopup(false)} style={{ backgroundColor: 'transparent', border: 'none', fontSize: '20px', cursor: 'pointer', position: 'absolute', top: '10px', right: '10px' }}>
              <FaTimes />
            </button>
            <input
              type="text"
              placeholder="Search by Cabin ID, Location, Building, Floor, etc."
              value={searchTerm}
              onChange={handleSearch}
              className="search-bar"
              style={{
                width: '100%',
                padding: '10px',
                margin: '10px 0',
                borderRadius: '8px',
                border: '1px solid #ccc',
                fontSize: '16px',
                boxSizing: 'border-box'
              }}
            />
            <table style={{ width: '100%', borderCollapse: 'collapse' }}>
              <thead>
                <tr>
                  <th>Cabin Number</th>
                  <th>Office Type</th>
                  <th>Office Zone</th>
                  <th>Seats</th>
                  <th>Window</th>
                  <th>Rate</th>
                </tr>
              </thead>
              <tbody>
                {filteredCabins.map(cabin => (
                  <tr key={cabin.OfficeNo} onClick={() => handleRowClick(cabin)} style={{ cursor: 'pointer', borderBottom: '1px solid #ddd' }}>
                    <td>{cabin.OfficeNo}</td>
                    <td>{cabin.OfficeType}</td>
                    <td>{cabin.OfficeZone}</td>
                    <td>{cabin.Seats}</td>
                    <td>{cabin.Window}</td>
                    <td>{cabin.Rate}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}

      {showSummaryModal && (
        <SummaryModal
          isOpen={showSummaryModal}
          onClose={() => setShowSummaryModal(false)}
          selectedCabins={selectedCabins}
        />
      )}
    </section>
  );
}

export default CabinDetails;
