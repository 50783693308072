import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Offices.css'; // CSS for styling
import DashboardHeader from './DashboardHeader';
import Sidebar from './Sidebar';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

const Offices = () => {
  const [offices, setOffices] = useState([]);
  const [officeData, setOfficeData] = useState({
    OfficeCode: '',
    OfficeDescription: '',
    Capacity: '',
  });
  const [currentOfficeId, setCurrentOfficeId] = useState(null);
  const [showAddOffice, setShowAddOffice] = useState(false);
  const [showEditPopup, setShowEditPopup] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);

  // Fetch all offices
  useEffect(() => {
    fetchOffices();
  }, []);

  const fetchOffices = async () => {
    try {
      const response = await axios.get('/api/offices'); // Ensure this endpoint returns status codes from the rooms table
      setOffices(response.data);
    } catch (error) {
      console.error('Error fetching offices:', error);
    }
  };

  // Handle adding a new office
  const handleAddOffice = async () => {
    try {
      await axios.post('/api/offices', officeData);
      fetchOffices(); // Refresh office list
      resetForm();
    } catch (error) {
      console.error('Error adding office:', error);
    }
  };

  // Handle updating an office
  const handleUpdateOffice = async (officeId) => {
    try {
      await axios.put(`/api/offices/${officeId}`, officeData);
      fetchOffices(); // Refresh office list
      resetForm();
    } catch (error) {
      console.error('Error updating office:', error);
    }
  };

  // Handle deleting an office
  const handleDeleteOffice = async (officeId) => {
    try {
      await axios.delete(`/api/offices/${officeId}`);
      fetchOffices(); // Refresh office list
      resetForm(); // Reset form to hide the popup
    } catch (error) {
      console.error('Error deleting office:', error);
    }
  };

  // Export functions
  const handleExportPDF = () => {
    const doc = new jsPDF();
    doc.autoTable({ html: '#officesTable' });
    doc.save('offices.pdf');
  };

  const handleExportExcel = () => {
    const ws = XLSX.utils.json_to_sheet(offices);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Offices');
    XLSX.writeFile(wb, 'offices.xlsx');
  };

  // Search functionality
  const handleSearch = () => {
    const results = offices.filter(office => 
      office.OfficeDescription.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setSearchResults(results);
  };

  // Reset the office form
  const resetForm = () => {
    setOfficeData({ OfficeCode: '', OfficeDescription: '', Capacity: '' });
    setCurrentOfficeId(null);
    setShowAddOffice(false);
    setShowEditPopup(false);
  };

  // Function to determine the status based on the status code
  const getStatusLabel = (statusCode) => {
    switch (statusCode) {
      case '0':
        return 'Vacant';
      case '1':
        return 'Occupied';
      case '007':
        return 'Negotiable';
      default:
        return 'Unknown';
    }
  };

  return (
    <div className="offices">
      <div className="offices-content">
        <h3>/Transaction/Offices</h3>
        <h2>Offices</h2>

        <div className="export-buttons">
          <button onClick={handleExportPDF} className="export-button" title="Export as PDF">
            📄
          </button>
          <button onClick={handleExportExcel} className="export-button" title="Export as Excel">
            <img src="https://upload.wikimedia.org/wikipedia/commons/7/73/Microsoft_Excel_2013-2019_logo.svg" alt="Excel" className="icon" />
          </button>
          <button onClick={() => setShowAddOffice(!showAddOffice)} className="export-button add-button" title="Add new office">
            ➕
          </button>
          <input 
            type="text" 
            placeholder="Search..." 
            value={searchQuery} 
            onChange={(e) => setSearchQuery(e.target.value)} 
          />
          <button onClick={handleSearch} className="export-button" title="Search data">
            <img src="https://e1.pngegg.com/pngimages/552/897/png-clipart-bundle-icon-search-blue-search-button-thumbnail.png" alt="Search" className="icon" />
          </button>
        </div>

        {showAddOffice && (
          <div className="edit-popup">
            <h4>Add New Office</h4>
            <input
              type="text"
              placeholder="Office Code"
              value={officeData.OfficeCode}
              onChange={(e) => setOfficeData({ ...officeData, OfficeCode: e.target.value })}
              required
            />
            <input
              type="text"
              placeholder="Office Description"
              value={officeData.OfficeDescription}
              onChange={(e) => setOfficeData({ ...officeData, OfficeDescription: e.target.value })}
              required
            />
            <input
              type="number"
              placeholder="Capacity"
              value={officeData.Capacity}
              onChange={(e) => setOfficeData({ ...officeData, Capacity: e.target.value })}
              required
            />
            <div>
              <button onClick={handleAddOffice}>Submit</button>
              <button onClick={resetForm}>Cancel</button>
            </div>
          </div>
        )}

        <table id="officesTable">
          <thead>
            <tr>
              <th>ID</th>
              <th>Office Code</th>
              <th>Description</th>
              <th>Capacity</th>
              <th>Status</th> {/* Added status column */}
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {(searchResults.length > 0 ? searchResults : offices).map((office, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{office.OfficeCode}</td>
                <td>{office.OfficeDescription}</td>
                <td>{office.Capacity}</td>
                <td>
                  {/* Display status based on office data */}
                  {getStatusLabel(office.status)} {/* Use the new status function */}
                </td>
                <td>
                  <button onClick={() => {
                    setOfficeData(office);
                    setCurrentOfficeId(office._id); // Set the current office ID for updating
                    setShowEditPopup(true);
                  }} title="Edit office">
                    <img src="https://freesvg.org/img/edit-blue.png" alt="Edit" className="icon" />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        {showEditPopup && (
          <div className="edit-popup">
            <h4>Edit Office</h4>
            <input
              type="text"
              placeholder="Office Code"
              value={officeData.OfficeCode}
              onChange={(e) => setOfficeData({ ...officeData, OfficeCode: e.target.value })}
              required
            />
            <input
              type="text"
              placeholder="Office Description"
              value={officeData.OfficeDescription}
              onChange={(e) => setOfficeData({ ...officeData, OfficeDescription: e.target.value })}
              required
            />
            <input
              type="number"
              placeholder="Capacity"
              value={officeData.Capacity}
              onChange={(e) => setOfficeData({ ...officeData, Capacity: e.target.value })}
              required
            />
            <div>
              <button onClick={() => handleUpdateOffice(currentOfficeId)}>Update</button>
              <button onClick={() => handleDeleteOffice(currentOfficeId)}><img src="https://cdn-icons-png.flaticon.com/512/6861/6861362.png" alt="Delete" className="icon" /></button> {/* Added delete button */}
              <button onClick={resetForm}>Cancel</button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Offices;
