import React from 'react';
import './HomePage.css';
import Navbar from './Navbar';
import Gallery from './Gallery';

const HomePage = () => {
  return (
    <div className="home-page">
      <Navbar />
      <header className="hero-section">
        <h1>Welcome to InsightSpace</h1>
        <p>Connect with building owners and find your perfect office space today!</p>
   
        

        <div className="buttons">
          <a href="/login" className="btn">Join Us Today</a>
        </div>
      </header>
      <div className="moving-text">
        <p>Rent Office Spaces | Manage Properties | Find Tenants | List Your Property</p>
      </div>
      <Gallery />
      <footer>
        <p>© 2024 InsightSpace. All Rights Reserved.</p>
      </footer>
    </div>
  );
};

export default HomePage;
