import React from 'react';

const UserDataPopup = ({ handleClose }) => {
  // Simulating user data for the example
  const userData = [
    { name: 'John Doe', position: 'Manager', email: 'john@example.com' },
    { name: 'Jane Smith', position: 'Engineer', email: 'jane@example.com' },
  ];

  return (
    <div className="popup">
      <div className="popup-content">
        <h3>User Data</h3>
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Position</th>
              <th>Email</th>
            </tr>
          </thead>
          <tbody>
            {userData.map((user, index) => (
              <tr key={index}>
                <td>{user.name}</td>
                <td>{user.position}</td>
                <td>{user.email}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <button onClick={handleClose}>Close</button>
      </div>
    </div>
  );
};

export default UserDataPopup;
