import React, { useState, useEffect } from 'react';
import UserDataPopup from './UserDataPopup';
import VacantFormPopup from './VacantFormPopup';
import TenantDetailsPopup from './TenantDetailsPopup';
import './OfficeDetailsPage.css'; // Adjust the path as necessary

const OfficeDetailsPopup = ({ handleClose }) => {
    const [isUserDataPopupOpen, setIsUserDataPopupOpen] = useState(false);
    const [isVacantFormPopupOpen, setIsVacantFormPopupOpen] = useState(false);
    const [isTenantPopupOpen, setIsTenantPopupOpen] = useState(false);
    const [selectedRoomCode, setSelectedRoomCode] = useState(null);
    const [officeDataArray, setOfficeDataArray] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    // Function to handle click on an occupied office
    const handleOccupiedClick = () => {
        setIsUserDataPopupOpen(true);
    };

    // Function to handle click on a vacant office
    const handleVacantClick = () => {
        setIsVacantFormPopupOpen(true);
    };

    // Function to handle row click to open tenant details popup
    const handleRowClick = (roomCode) => {
        setSelectedRoomCode(roomCode);
        setIsTenantPopupOpen(true);
    };

    // Function to close all popups
    const closePopups = () => {
        console.log('Close button clicked');
        setIsUserDataPopupOpen(false);
        setIsVacantFormPopupOpen(false);
        setIsTenantPopupOpen(false);
        if (handleClose && typeof handleClose === 'function') {
            handleClose(); // Call handleClose if it’s a function
        } else {
            console.warn('handleClose is not a function');
        }
    };

    // Fetch office data from the backend API
    useEffect(() => {
        const fetchOfficeData = async () => {
            try {
                const response = await fetch('/api/office-and-room-data', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({}) // Add parameters here if necessary
                });
                
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                const data = await response.json();
                console.log('Fetched office data:', data);
                
                if (!Array.isArray(data)) {
                    throw new Error('Data format is not an array');
                }
    
                setOfficeDataArray(data);
            } catch (error) {
                console.error('Error fetching office data:', error);
                setError('Failed to load office data. Please try again later.');
            } finally {
                setLoading(false);
            }
        };
    
        fetchOfficeData();
    }, []);

    return (
        <div className="popup">
            <div className="popup-content">
                <h3>Office Details</h3>
                {loading && <p>Loading office data...</p>}
                {error && <p>{error}</p>}
                {officeDataArray.length > 0 && (
                    <table>
                        <thead>
                            <tr>
                                <th>Office Code</th>
                                <th>Office Description</th>
                                <th>Capacity</th>
                                <th>Building Code</th>
                                <th>Floor Code</th>
                                <th>Room Code</th>
                                <th>Building Name</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {officeDataArray.map((office, index) => (
                                <tr key={index} onClick={() => handleRowClick(office.RoomCode)}>
                                    <td>{office.OfficeCode}</td>
                                    <td>{office.OfficeDescription}</td>
                                    <td>{office.Capacity}</td>
                                    <td>{office.BuildingCode}</td>
                                    <td>{office.FloorCode}</td>
                                    <td>{office.RoomCode}</td>
                                    <td>{office.BuildingName}</td>
                                    <td>
                                        {office.StatusCode === 0 ? 'Vacant' :
                                         office.StatusCode === 1 ? 'Occupied' : 
                                         office.StatusCode === 7 ? 'In Negotiation' : 
                                         'Unknown'}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                )}
                <button onClick={handleOccupiedClick}>Occupied</button>
                <button onClick={handleVacantClick}>Vacant</button>
                <button onClick={closePopups} aria-label="Close">
                    ❌ Close
                </button>
            </div>

            {/* User Data Popup */}
            {isUserDataPopupOpen && (
                <UserDataPopup handleClose={() => setIsUserDataPopupOpen(false)} />
            )}

            {/* Vacant Form Popup */}
            {isVacantFormPopupOpen && (
                <VacantFormPopup handleClose={() => setIsVacantFormPopupOpen(false)} />
            )}

            {/* Tenant Details Popup */}
            {isTenantPopupOpen && selectedRoomCode && (
                <TenantDetailsPopup 
                    roomCode={selectedRoomCode}
                    handleClose={() => setIsTenantPopupOpen(false)} 
                />
            )}
        </div>
    );
};

export default OfficeDetailsPopup;
