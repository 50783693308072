import React, { useState, useEffect } from 'react';
import './Booking.css';
import CabinDetails from './CabinDetails';
import SummaryModal from './SummaryModal';

import axios from 'axios';
import CustomerListModal from './CustomerListModal'; // Import the separate modal component

const Booking = () => {


  const [isModalOpen, setIsModalOpen] = useState(false);

  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [numberOfUsers, setNumberOfUsers] = useState(); // Example of number of users
  
  const [customers, setCustomers] = useState([]);
  const [selectedCustomerId, setSelectedCustomerId] = useState(null);
  const [customerInfo, setCustomerInfo] = useState({});
  const [bookingInfo, setBookingInfo] = useState({
    bookingNo: '',
    bookingDate: '',
    from: '',
    to: '',
    tenure: '',
  });
  const [additionalInfo, setAdditionalInfo] = useState({
    lockInPeriod: '',
    numberOfUsers: '',
    securityDeposit: '',
    teaCoffeePerUser: '',
    meetingCredit: '',
    printingPagesCredit: '',
  });

  const handleSummaryClick = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

 //const [selectedCabins, setSelectedCabins] = useState([]);
 // const [selectedCabinId, setSelectedCabinId] = useState('');
  const [isCustomerModalOpen, setIsCustomerModalOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredCustomers, setFilteredCustomers] = useState(customers);

  useEffect(() => {
    fetch('/api/customers')
      .then((response) => response.json())
      .then((data) => {
        setCustomers(data);
        setFilteredCustomers(data); // Set the full list of customers initially
      })
      .catch((error) => console.error('Error fetching customer data:', error));
  }, []);

  useEffect(() => {
    if (searchTerm.trim() === '') {
      setFilteredCustomers(customers);
    } else {
      const searchResults = customers.filter((customer) =>
        customer.CustomerName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        customer.EMailID.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredCustomers(searchResults);
    }
  }, [searchTerm, customers]);

  const handleCustomerSelect = (customerId) => {
    const selectedCustomer = customers.find(
      (customer) => customer.EMailID === customerId
    );
    setSelectedCustomerId(customerId);
    setCustomerInfo(selectedCustomer || {});
    setIsCustomerModalOpen(false);
  };

   // Define the empty handleSave function
   const handleSave = () => {
    // This function currently does nothing
    console.log("Save button clicked");
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleSearchClick = () => {
    setIsCustomerModalOpen(true);
  };

  const calculateTenure = () => {
    const fromDate = new Date(bookingInfo.from);
    const toDate = new Date(bookingInfo.to);
    const tenure = Math.floor((toDate - fromDate) / (1000 * 60 * 60 * 24));
    setBookingInfo({ ...bookingInfo, tenure });
  };

  const handleBookingInfoChange = (e) => {
    const { name, value } = e.target;
    setBookingInfo({ ...bookingInfo, [name]: value });
  };

  const handleAdditionalInfoChange = (e) => {
    const { name, value } = e.target;
    setAdditionalInfo({ ...additionalInfo, [name]: value });
  };

  

  const handlePrint = () => {
    const printContent = document.getElementById('printable-booking-info'); // Get the printable section
    const printWindow = window.open('', '_blank');
    
    // Create the content for the print window
    printWindow.document.write(`
      <html>
        <head>
          <title>Booking Details</title>
          <style>
            body { font-family: Arial, sans-serif; padding: 20px; }
            h3 { color: #333; }
            .booking-info, .customer-info { margin-bottom: 1rem; }
            .inline-label-input { margin-bottom: 0.5rem; }
            .inline-label-input label { font-weight: bold; }
          </style>
        </head>
        <body>
          <h2>Booking Details</h2>
          <div id="printable-booking-info">${printContent.innerHTML}</div>
        </body>
      </html>
    `);
    
    // Print the content
    printWindow.document.close();
    printWindow.print();
  };
  




  
    const [cabins, setCabins] = useState([]); // All cabin data
    const [selectedCabins, setSelectedCabins] = useState([]); // Selected cabins for display
    const [selectedCabinId, setSelectedCabinId] = useState('');
   // const [additionalInfo, setAdditionalInfo] = useState({ numberOfUsers: 0 });
  
  // Fetch cabin data from the API
  useEffect(() => {
    const fetchCabinData = async () => {
      try {
        const response = await axios.get('/api/office-data');
        setCabins(response.data);
      } catch (error) {
        console.error('Error fetching cabin data:', error);
      }
    };
    fetchCabinData();
  }, []);

    // Handle the number of users input change
    const handleUserInputChange = (e) => {
      setNumberOfUsers(e.target.value);
    };

  const handleCabinSelect = (e) => {
    setSelectedCabinId(e.target.value);
  };

  const addCabin = () => {
    const selectedCabin = cabins.find(cabin => cabin.OfficeID === selectedCabinId); // Adjust ID as per API
    if (selectedCabin && !selectedCabins.some(c => c.OfficeID === selectedCabinId)) {
      const newSelectedCabins = [...selectedCabins, selectedCabin];
      setSelectedCabins(newSelectedCabins);
      const totalSeats = newSelectedCabins.reduce((total, cabin) => total + cabin.Seats, 0);
      setAdditionalInfo({ ...additionalInfo, numberOfUsers: totalSeats });
      setSelectedCabinId(''); // Reset dropdown after selection
    }
  };

  const removeCabin = (cabinId) => {
    const newSelectedCabins = selectedCabins.filter(cabin => cabin.OfficeID !== cabinId);
    setSelectedCabins(newSelectedCabins);
    const totalSeats = newSelectedCabins.reduce((total, cabin) => total + cabin.Seats, 0);
    setAdditionalInfo({ ...additionalInfo, numberOfUsers: totalSeats });
  };


  return (
    <div
  className="booking-container"
  style={{
    display: 'grid',
    gridTemplateColumns: isSidebarOpen ? '300px 1fr' : '0 1fr',
    gap: '0rem',
    transition: 'grid-template-columns 0.3s ease-in-out',
  }}
>

  <main className="content" style={{ padding: '0rem' }}>

    {/* Customer Info Section */}
<section className="section section1" style={{ marginBottom: '0rem' }}>
  <div className="customer-info" style={{
    marginBottom: '0rem',
    backgroundColor: '#f9f9f9',
    borderRadius: '8px',
    border: '1px solid #ddd',
    boxShadow: '0 2px 6px rgba(0, 0, 0, 0.1)',
    padding: '0.5rem'
  }}>
    <h3 style={{ fontSize: '1rem', marginBottom: '0.5rem', color: '#333' }}>Customer Info</h3>

    {/* Search Customer Input */}
    <div className="customer-search" style={{
      display: 'flex',
      alignItems: 'center',
      marginBottom: '0.5rem'
    }}>
      <input
        type="text"
        placeholder="Search Customer"
        value={searchTerm}
        onChange={handleSearchChange}
        style={{
          padding: '0.22rem',
          fontSize: '0.875rem',
          width: '80%',
          marginRight: '0.5rem',
          border: '1px solid #ccc',
          borderRadius: '4px',
        }}
      />
      <button onClick={handleSearchClick} className="search-icon" style={{
        background: 'none',
        border: 'none',
        cursor: 'pointer',
        fontSize: '1rem'
      }}>
        &#8230;
      </button>
    </div>

    {/* Customer Details Display */}
    {selectedCustomerId && customerInfo.CustomerName && (
      <div style={{ marginTop: '0.5rem' }}>
        <div className="inline-label-input" style={{ display: 'flex', alignItems: 'center', marginBottom: '0.25rem' }}>
          <label style={{ fontSize: '0.875rem', color: '#333', width: '30%' }}>Name:</label>
          <p style={{ fontSize: '0.875rem', color: '#555', width: '70%', margin: 0 }}>{customerInfo.CustomerName}</p>
        </div>
        <div className="inline-label-input" style={{ display: 'flex', alignItems: 'center', marginBottom: '0.25rem' }}>
          <label style={{ fontSize: '0.875rem', color: '#333', width: '30%' }}>Email:</label>
          <p style={{ fontSize: '0.875rem', color: '#555', width: '70%', margin: 0 }}>{customerInfo.EMailID}</p>
        </div>
        <div className="inline-label-input" style={{ display: 'flex', alignItems: 'center', marginBottom: '0.25rem' }}>
          <label style={{ fontSize: '0.875rem', color: '#333', width: '30%' }}>Mobile:</label>
          <p style={{ fontSize: '0.875rem', color: '#555', width: '70%', margin: 0 }}>{customerInfo.Mobile}</p>
        </div>
        <div className="inline-label-input" style={{ display: 'flex', alignItems: 'center', marginBottom: '0.25rem' }}>
          <label style={{ fontSize: '0.875rem', color: '#333', width: '30%' }}>Contact Person:</label>
          <p style={{ fontSize: '0.875rem', color: '#555', width: '70%', margin: 0 }}>{customerInfo.ContactPerson}</p>
        </div>
        <div className="inline-label-input" style={{ display: 'flex', alignItems: 'center', marginBottom: '0.25rem' }}>
          <label style={{ fontSize: '0.875rem', color: '#333', width: '30%' }}>Location:</label>
          <p style={{ fontSize: '0.875rem', color: '#555', width: '70%', margin: 0 }}>{customerInfo.Location}</p>
        </div>
      </div>
    )}
  </div>

      {/* Booking Info Section */}
      <div className="booking-info" id="print-booking-info" style={{
        backgroundColor: '#f9f9f9',
        borderRadius: '8px',
        border: '1px solid #ddd',
        boxShadow: '0 2px 6px rgba(0, 0, 0, 0.1)',
        display: 'flex',
        flexDirection: 'column',
        gap: '0.5rem',
        padding: '1rem',
      }}>
        <h3 style={{ fontSize: '1rem', color: '#333' }}>Booking Info</h3>

        <div className="inline-label-input">
          <label htmlFor="bookingNo">Booking No</label>
          <input
            id="bookingNo"
            type="text"
            placeholder="Booking No"
            name="bookingNo"
            value={bookingInfo.bookingNo}
            onChange={handleBookingInfoChange}
            style={{
              padding: '0.22rem',
              fontSize: '0.875rem',
              border: '1px solid #ccc',
              borderRadius: '4px',
              width: '80%',
            }}
          />
        </div>

        <div className="inline-label-input">
          <label htmlFor="bookingDate">Booking Date</label>
          <input
            id="bookingDate"
            type="date"
            name="bookingDate"
            value={bookingInfo.bookingDate}
            onChange={handleBookingInfoChange}
            style={{
              padding: '0.22rem',
              fontSize: '0.875rem',
              border: '1px solid #ccc',
              borderRadius: '4px',
              width: '80%',
            }}
          />
        </div>

        <div className="inline-label-input">
          <label htmlFor="from">From</label>
          <input
            id="from"
            type="date"
            name="from"
            value={bookingInfo.from}
            onChange={handleBookingInfoChange}
            onBlur={calculateTenure}
            style={{
              padding: '0.22rem',
              fontSize: '0.875rem',
              border: '1px solid #ccc',
              borderRadius: '4px',
              width: '80%',
            }}
          />
        </div>

        <div className="inline-label-input">
          <label htmlFor="to">To</label>
          <input
            id="to"
            type="date"
            name="to"
            value={bookingInfo.to}
            onChange={handleBookingInfoChange}
            onBlur={calculateTenure}
            style={{
              padding: '0.22rem',
              fontSize: '0.875rem',
              border: '1px solid #ccc',
              borderRadius: '4px',
              width: '80%',
            }}
          />
        </div>

        {/* Tenure Display */}
        <p style={{ fontSize: '1rem', color: '#333', fontWeight: '600' }}>
          Tenure: {bookingInfo.tenure} days
        </p>
      </div>
    </section>

 {/* Additional Info Section */}
<section className="section section3" style={{
  backgroundColor: '#f9f9f9',
  borderRadius: '8px',
  border: '1px solid #ddd',
  boxShadow: '0 2px 6px rgba(0, 0, 0, 0.1)',
  padding: '0.25rem',
  marginBottom: '0rem'
}}>
  <h3 style={{ fontSize: '1rem', color: '#333', marginBottom: '0.5rem' }}>Additional Info</h3>

  <div style={{
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gap: '0.5rem'
  }}>
    {/* Lock-in Period */}
    <div className="inline-label-input" style={{ display: 'flex', alignItems: 'center' }}>
      <label htmlFor="lockInPeriod" style={{
        fontSize: '0.875rem',
        color: '#333',
        width: '40%'
      }}>Lock-in Period</label>
      <input
        id="lockInPeriod"
        type="text"
        placeholder="Lock-in Period"
        name="lockInPeriod"
        value={additionalInfo.lockInPeriod}
        onChange={handleAdditionalInfoChange}
        style={{
          padding: '0.22rem',
          fontSize: '0.875rem',
          border: '1px solid #ccc',
          borderRadius: '4px',
          width: '60%',
        }}
      />
    </div>

    {/* Number of Users Section */}
    <div className="inline-label-input" style={{ display: 'flex', alignItems: 'center' }}>
        <label htmlFor="numberOfUsers" style={{
          fontSize: '0.875rem',
          color: '#333',
          width: '40%',
        }}>
          Number of Users
        </label>
        <input
          id="numberOfUsers"
          type="number"
          placeholder="Number of Users"
          name="numberOfUsers"
          value={numberOfUsers}
          onChange={handleUserInputChange}  // Bind the input field to state
          style={{
            padding: '0.22rem',
            fontSize: '0.875rem',
            border: '1px solid #ccc',
            borderRadius: '4px',
            width: '60%',
          }}
        />
      </div>
    {/* Security Deposit */}
    <div className="inline-label-input" style={{ display: 'flex', alignItems: 'center' }}>
      <label htmlFor="securityDeposit" style={{
        fontSize: '0.875rem',
        color: '#333',
        width: '40%'
      }}>Security Deposit</label>
      <input
        id="securityDeposit"
        type="text"
        placeholder="Security Deposit"
        name="securityDeposit"
        value={additionalInfo.securityDeposit}
        onChange={handleAdditionalInfoChange}
        style={{
          padding: '0.22rem',
          fontSize: '0.875rem',
          border: '1px solid #ccc',
          borderRadius: '4px',
          width: '60%',
        }}
      />
    </div>

    {/* Tea/Coffee per User */}
    <div className="inline-label-input" style={{ display: 'flex', alignItems: 'center' }}>
      <label htmlFor="teaCoffeePerUser" style={{
        fontSize: '0.875rem',
        color: '#333',
        width: '40%'
      }}>Tea/Coffee per User</label>
      <input
        id="teaCoffeePerUser"
        type="text"
        placeholder="Tea/Coffee per User"
        name="teaCoffeePerUser"
        value={additionalInfo.teaCoffeePerUser}
        onChange={handleAdditionalInfoChange}
        style={{
          padding: '0.22rem',
          fontSize: '0.875rem',
          border: '1px solid #ccc',
          borderRadius: '4px',
          width: '60%',
        }}
      />
    </div>

    {/* Meeting Credit */}
    <div className="inline-label-input" style={{ display: 'flex', alignItems: 'center' }}>
      <label htmlFor="meetingCredit" style={{
        fontSize: '0.875rem',
        color: '#333',
        width: '40%'
      }}>Meeting Credit</label>
      <input
        id="meetingCredit"
        type="text"
        placeholder="Meeting Credit"
        name="meetingCredit"
        value={additionalInfo.meetingCredit}
        onChange={handleAdditionalInfoChange}
        style={{
          padding: '0.22rem',
          fontSize: '0.875rem',
          border: '1px solid #ccc',
          borderRadius: '4px',
          width: '60%',
        }}
      />
    </div>

    {/* Printing Pages Credit */}
    <div className="inline-label-input" style={{ display: 'flex', alignItems: 'center' }}>
      <label htmlFor="printingPagesCredit" style={{
        fontSize: '0.875rem',
        color: '#333',
        width: '40%'
      }}>Printing Pages Credit</label>
      <input
        id="printingPagesCredit"
        type="text"
        placeholder="Printing Pages Credit"
        name="printingPagesCredit"
        value={additionalInfo.printingPagesCredit}
        onChange={handleAdditionalInfoChange}
        style={{
          padding: '0.22rem',
          fontSize: '0.875rem',
          border: '1px solid #ccc',
          borderRadius: '4px',
          width: '60%',
        }}
      />
    </div>
  </div>
</section>

    {/* Cabin Details Section */}
    <section className="section section4">
      <h3>Cabin Details</h3>
      <CabinDetails />
      

      
    </section>

    

      {/* Button to open Summary Modal */}
      <button onClick={handleSummaryClick}>Save</button>

       {/* Summary Modal */}
       <SummaryModal
          isOpen={isModalOpen}
          onClose={handleCloseModal}
          customerInfo={customerInfo}
          bookingInfo={bookingInfo}
          additionalInfo={additionalInfo}
        />

  </main>







  {/* Customer List Modal */}
  <CustomerListModal
    isOpen={isCustomerModalOpen}
    customers={filteredCustomers}
    onSelectCustomer={handleCustomerSelect}
    onClose={() => setIsCustomerModalOpen(false)}
  />


</div>

  );
};

export default Booking;