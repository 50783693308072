import React from 'react';
import './SummaryModal.css';
import './CabinDetails';

const SummaryModal = ({ isOpen, onClose, customerInfo, bookingInfo, additionalInfo, selectedCabins }) => {
  if (!isOpen) return null;

  return (
    <div className="summary-modal-overlay">
      <div className="summary-modal">
        <h2>Booking Summary</h2>

        {/* Customer Info Section */}
        <div className="summary-section">
          <h3>Customer Info</h3>
          <p><strong>Name:</strong> {customerInfo.CustomerName}</p>
          <p><strong>Email:</strong> {customerInfo.EMailID}</p>
          <p><strong>Mobile:</strong> {customerInfo.Mobile}</p>
          <p><strong>Location:</strong> {customerInfo.Location}</p>
        </div>

        {/* Booking Info Section */}
        <div className="summary-section">
          <h3>Booking Info</h3>
          <p><strong>Booking No:</strong> {bookingInfo.bookingNo}</p>
          <p><strong>Booking Date:</strong> {bookingInfo.bookingDate}</p>
          <p><strong>From:</strong> {bookingInfo.from}</p>
          <p><strong>To:</strong> {bookingInfo.to}</p>
          <p><strong>Tenure:</strong> {bookingInfo.tenure} days</p>
        </div>

        {/* Additional Info Section */}
        <div className="summary-section">
          <h3>Additional Info</h3>
          <p><strong>Lock-in Period:</strong> {additionalInfo.lockInPeriod}</p>
          <p><strong>Number of Users:</strong> {additionalInfo.numberOfUsers}</p>
          <p><strong>Security Deposit:</strong> {additionalInfo.securityDeposit}</p>
          <p><strong>Tea/Coffee per User:</strong> {additionalInfo.teaCoffeePerUser}</p>
          <p><strong>Meeting Credit:</strong> {additionalInfo.meetingCredit}</p>
          <p><strong>Printing Pages Credit:</strong> {additionalInfo.printingPagesCredit}</p>
        </div>

        {/* Cabin Details Section */}
        <div className="summary-section">
          <h3>Cabin Details</h3>
          {selectedCabins && selectedCabins.length > 0 ? (
            <table className="cabin-details-table">
              <thead>
                <tr>
                  <th>Cabin Number</th>
                  <th>Seats</th>
                  <th>Rate</th>
                  <th>Discount Rate</th>
                  <th>Final Rate</th>
                </tr>
              </thead>
              <tbody>
                {selectedCabins.map((cabin, index) => (
                  <tr key={index}>
                    <td>{cabin.OfficeNo}</td>
                    <td>{cabin.Seats}</td>
                    <td>{cabin.Rate}</td>
                    <td>{cabin.DiscountRate}</td>
                    <td>{cabin.FinalRate}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <p>No cabin selected.</p>
          )}
        </div>

        {/* Modal Actions */}
        <div className="modal-actions">
          <button onClick={onClose}>Close</button>
        </div>
      </div>
    </div>
  );
};

export default SummaryModal;
