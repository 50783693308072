import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './CustomerModal.css';

const CustomerModal = ({ isOpen, onClose }) => {
  const [customerData, setCustomerData] = useState({
    ContactPerson: '',
    Mobile: '',
    Email: '',
    BPName: '',
    Add1: '',
    Add2: '',
    City: '',
    State: '',
    Pin: '',
    Country: '',
    GSTRegistered: 'No',
    GSTIN: '',
    CreatedBy: '',  // Initialize as empty
    UpdatedBy: '',  // Initialize as empty
  });

  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (isOpen) {
      const emailId = sessionStorage.getItem('EmailId'); // Get EmailID from session storage
      const suCode = sessionStorage.getItem('suCode'); // Retrieve suCode from session storage if required
      console.log("EmailID from session:", emailId);
  
      if (emailId) {
        setCustomerData((prevData) => ({
          ...prevData,
          CreatedBy: emailId,
          UpdatedBy: emailId,
          suCode: suCode // Set suCode if needed
        }));
      }
    }
  }, [isOpen]); // Run effect when modal opens

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCustomerData({ ...customerData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      // Get the JWT token from sessionStorage
      const token = sessionStorage.getItem('token');
      
      if (!token) {
        console.error("No token found. User is not authorized.");
        setIsSubmitting(false);
        return;
      }

      // Send the POST request to the backend API with the Authorization header
      await axios.post('/api/acustomers', customerData, {
        headers: {
          'Authorization': `Bearer ${token}`,  // Include the token in the Authorization header
          'Content-Type': 'application/json',  // Ensure content type is set to JSON
        },
      });

      // Reset the form after submission
      setCustomerData({
        ContactPerson: '',
        Mobile: '',
        Email: '',
        BPName: '',
        Add1: '',
        Add2: '',
        City: '',
        State: '',
        Pin: '',
        Country: '',
        GSTRegistered: 'No',
        GSTIN: '',
        CreatedBy: customerData.CreatedBy, // Maintain CreatedBy
        UpdatedBy: customerData.UpdatedBy, // Maintain UpdatedBy
      });

      onClose(); // Close the modal after successful submission
    } catch (error) {
      console.error('Error adding customer:', error);
      setIsSubmitting(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
      <button className="close-btn" onClick={onClose}>×</button>
        <h3>Add New Customer</h3>
        <form onSubmit={handleSubmit}>
          {/* Customer details form fields */}
          <div className="form-group">
            <label htmlFor="ContactPerson">Contact Person</label>
            <input
              type="text"
              id="ContactPerson"
              name="ContactPerson"
              value={customerData.ContactPerson}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="Mobile">Mobile</label>
            <input
              type="tel"
              id="Mobile"
              name="Mobile"
              value={customerData.Mobile}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="Email">Email</label>
            <input
              type="email"
              id="Email"
              name="Email"
              value={customerData.Email}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="BPName">Business/Personal Name</label>
            <input
              type="text"
              id="BPName"
              name="BPName"
              value={customerData.BPName}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="Add1">Address Line 1</label>
            <input
              type="text"
              id="Add1"
              name="Add1"
              value={customerData.Add1}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="Add2">Address Line 2</label>
            <input
              type="text"
              id="Add2"
              name="Add2"
              value={customerData.Add2}
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <label htmlFor="City">City</label>
            <input
              type="text"
              id="City"
              name="City"
              value={customerData.City}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="State">State</label>
            <input
              type="text"
              id="State"
              name="State"
              value={customerData.State}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="Pin">Pin Code</label>
            <input
              type="text"
              id="Pin"
              name="Pin"
              value={customerData.Pin}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="Country">Country</label>
            <input
              type="text"
              id="Country"
              name="Country"
              value={customerData.Country}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="GSTRegistered">GST Registered</label>
            <select
              id="GSTRegistered"
              name="GSTRegistered"
              value={customerData.GSTRegistered}
              onChange={handleChange}
            >
              <option value="No">No</option>
              <option value="Yes">Yes</option>
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="GSTIN">GSTIN</label>
            <input
              type="text"
              id="GSTIN"
              name="GSTIN"
              value={customerData.GSTIN}
              onChange={handleChange}
            />
          </div>
          <button type="submit" disabled={isSubmitting}>
            {isSubmitting ? 'Adding...' : 'Add Customer'}
          </button>
        </form>
        
      </div>
    </div>
  );
};

export default CustomerModal;
