import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import OfficeAssetsPage from './components/OfficeAssetsPage';
import EmployeeManagement from './components/EmployeeManagement';
import Assets from './components/Assets';
import ConferenceRooms from './components/ConferenceRooms';
import Offices from './components/Offices';
import Dashboard from './components/Dashboard';
import Login from './components/Login';
import Sidebar from './components/Sidebar';
import DashboardHeader from './components/DashboardHeader';
import CustomerForm from './components/CustomerForm';
import OfficeForm from './components/OfficeForm';
import PDFPopup from './components/PDFPopup';
import FloorMap from './components/FloorMap';
import RoomDataPopup from './components/RoomDataPopup';
import OfficeDetailsPopup from './components/OfficeDetailsPopup';
import PdfViewer from './components/PdfViewer';
import Contract from './components/Contract';
import DataPopup from './components/DataPopup';
import Booking from './components/Booking';
import Breadcrumb from './components/Breadcrumb';
import HomePage from './components/HomePage';
import FClickFloor from './components/FClickfloor';

const App = () => {
  return (
    <Router>
      <div>
        <Breadcrumb />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/login" element={<Login />} />
          <Route path="/pdf-viewer" element={<PdfViewer />} />
          <Route path="/employees" element={<><DashboardHeader /><Sidebar /><EmployeeManagement /></>} />
          <Route path="/assets" element={<><DashboardHeader /><Sidebar /><Assets /></>} />
          <Route path="/conference-rooms" element={<><DashboardHeader /><Sidebar /><ConferenceRooms /></>} />
          <Route path="/offices" element={<><DashboardHeader /><Sidebar /><Offices /></>} />
          <Route path="/dashboard" element={<><DashboardHeader /><Sidebar /><Dashboard /></>} />
          <Route path="/booking" element={<><DashboardHeader /><Sidebar /><Booking /></>} />
          <Route path="/customer-form" element={<><DashboardHeader /><Sidebar /><CustomerForm /></>} />
          <Route path="/office-form" element={<><DashboardHeader /><Sidebar /><OfficeForm /></>} />
          <Route path="/contract" element={<><DashboardHeader /><Sidebar /><Contract /></>} />
          <Route path="/PDFPopup" element={<PDFPopup />} />
          <Route path="/office-assets" element={<><DashboardHeader /><Sidebar /><OfficeAssetsPage /></>} />
          <Route path="/floor-map" element={<><FloorMap /><DashboardHeader /></>} />
          <Route path="/Click-floor" element={<FClickFloor />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
