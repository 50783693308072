import React, { useState, useEffect } from 'react';
import { pdfjs } from 'react-pdf';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import { useNavigate } from 'react-router-dom';
import Sidebar from './Sidebar';
import DashboardHeader from './DashboardHeader';
import RoomDataPopup from './RoomDataPopup';
import OfficeDetailsPopup from './OfficeDetailsPopup';
import UserDataPopup from './UserDataPopup';
import VacantFormPopup from './VacantFormPopup';
import './Dashboard.css';
import './PDFPopup';
import './Booking';
import useIdleTimer from './useIdleTimer';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const Dashboard = () => {
  const [meetingRequests, setMeetingRequests] = useState([
    '📅 Meeting with John',
    '📞 Client call with HR',
  ]);
  const [newRequest, setNewRequest] = useState('');
  const [roomPopupData, setRoomPopupData] = useState([]);
  const [isRoomPopupOpen, setIsRoomPopupOpen] = useState(false);
  const [officePopupData, setOfficePopupData] = useState(null);
  const [isOfficePopupOpen, setIsOfficePopupOpen] = useState(false);
  const [isUserDataPopupOpen, setIsUserDataPopupOpen] = useState(false);
  const [isVacantFormPopupOpen, setIsVacantFormPopupOpen] = useState(false);

  const navigate = useNavigate();

  // Sample office data for the bar chart
  const officeData = [
    { roomNumber: '101', pcCount: 5, printerCount: 2 },
    { roomNumber: '102', pcCount: 7, printerCount: 1 },
    { roomNumber: '103', pcCount: 3, printerCount: 4 },
    { roomNumber: '104', pcCount: 6, printerCount: 3 },
    { roomNumber: '105', pcCount: 8, printerCount: 5 },
  ];

  const roomData = [{ name: 'Rooms', total: 50, occupied: 20 }];

  const data = officeData.map((office) => ({
    roomNumber: office.roomNumber,
    pcCount: parseInt(office.pcCount, 10),
    printerCount: parseInt(office.printerCount, 10),
  }));

  const handleAddRequest = () => {
    if (newRequest.trim()) {
      setMeetingRequests((prevRequests) => [...prevRequests, newRequest]);
      setNewRequest(''); // Clear input
    }
  };

  const handleAcceptRequest = (index) => {
    console.log(`Accepted request: ${meetingRequests[index]}`);
    setMeetingRequests((prevRequests) => prevRequests.filter((_, i) => i !== index));
  };

  const handleDeclineRequest = (index) => {
    console.log(`Declined request: ${meetingRequests[index]}`);
    setMeetingRequests((prevRequests) => prevRequests.filter((_, i) => i !== index));
  };

  const handleBarChartClick = () => {
    const exampleData = [
      { building: 'Building A', floor: 1, total: 10, engaged: 5, available: 5 },
      { building: 'Building B', floor: 2, total: 8, engaged: 3, available: 5 },
      { building: 'Building C', floor: 3, total: 6, engaged: 4, available: 2 },
    ];

    setRoomPopupData(exampleData);
    setIsRoomPopupOpen(true);
  };

  const handleOfficeColumnClick = (office) => {
    const exampleOfficeData = {
      officeCode: office.roomNumber,
      description: `Office located in ${office.roomNumber}`,
      status: office.pcCount > 5 ? 'Active' : 'Inactive',
    };

    setOfficePopupData(exampleOfficeData);
    setIsOfficePopupOpen(true);
  };

  const handleCloseRoomPopup = () => {
    setIsRoomPopupOpen(false);
  };

  const handleCloseOfficePopup = () => {
    setIsOfficePopupOpen(false);
  };

  const handleOccupiedClick = () => {
    setIsUserDataPopupOpen(true);
  };

  const handleVacantClick = () => {
    setIsVacantFormPopupOpen(true);
  };

  const handleCloseUserDataPopup = () => {
    setIsUserDataPopupOpen(false);
  };

  const handleCloseVacantFormPopup = () => {
    setIsVacantFormPopupOpen(false);
  };

  const handleOpenPopup = () => {
    window.open('/ASBUILT_COSPACIO_8TH_FLOOR.pdf');
  };

  const handleFloorMapClick = () => {
    navigate('/floor-map');
  };

  useEffect(() => {
    let timer;

    const handleIdle = () => {
      // console.log('User is idle. Logging out...');
      // sessionStorage.removeItem('Token'); // Clear the token or session storage
      // navigate('/login'); // Redirect to login page
    };

    const resetTimer = () => {
      clearTimeout(timer);
      timer = setTimeout(handleIdle, 30000); // 30 seconds
    };

    // Set up event listeners for user activity
    const events = ['mousemove', 'keydown', 'click', 'scroll'];

    // Attach event listeners to reset the idle timer on activity
    events.forEach(event => {
      window.addEventListener(event, resetTimer);
    });
    

    resetTimer(); // Start the timer

    // Cleanup function to clear timeout and remove event listeners
    return () => {
      clearTimeout(timer);
      events.forEach(event => {
        window.removeEventListener(event, resetTimer);
      });
    };
  }, [navigate]); //

  return (
    <div className="dashboard-container">
      <div className="main-content">
        {/* Master creation and assets overview */}
        <div className="section analysis-section">
          <h5></h5>
          <div className="analysis-cards">
            <div className="card">Total Assets: 50</div>
            <div className="card">Occupied Rooms: 20</div>
            <div className="card">Available Rooms: 30</div>
          </div>
        </div>

        {/* Charts section */}
        <div className="section charts-section">
          {/* First Bar Chart: Total Rooms vs. Occupied Rooms */}
          <div className="chart-wrapper" onClick={handleBarChartClick}>
            <h2>Total Rooms vs. Occupied Rooms</h2>
            <BarChart width={300} height={300} data={roomData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="total" fill="#8884d8" name="Total Rooms" />
              <Bar dataKey="occupied" fill="#82ca9d" name="Occupied Rooms" />
            </BarChart>
          </div>

          {/* Office Assets Overview Bar Chart */}
          <div className="chart-wrapper">
            <h2>Office Assets Overview</h2>
            <BarChart width={400} height={300} data={data}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="roomNumber" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="pcCount" fill="#8884d8" name="PC Count" onClick={handleFloorMapClick} />
              <Bar dataKey="printerCount" fill="#82ca9d" name="Printer Count" />
            </BarChart>
          </div>
        </div>

        <button onClick={handleOpenPopup}>Open floor Map</button>
        <button onClick={handleFloorMapClick}>Open 3d Floor Map</button>

        {/* Meeting Requests section */}
        <div className="section meeting-section">
          <h3>Meeting Requests</h3>
          <div className="meeting-bucket">
            {meetingRequests.length > 0 ? (
              <ul>
                {meetingRequests.map((request, index) => (
                  <li key={index} className="meeting-request-item">
                    {request}
                    <button className="accept-button" onClick={() => handleAcceptRequest(index)}>
                      Accept
                    </button>
                    <button className="decline-button" onClick={() => handleDeclineRequest(index)}>
                      Decline
                    </button>
                  </li>
                ))}
              </ul>
            ) : (
              <p>No meeting requests</p>
            )}
          </div>
          <input
            type="text"
            value={newRequest}
            onChange={(e) => setNewRequest(e.target.value)}
            placeholder="Add new meeting request"
          />
          <button onClick={handleAddRequest} className="add-request-button">
            Add Request
          </button>
        </div>

        {isRoomPopupOpen && (
          <RoomDataPopup data={roomPopupData} onClose={handleCloseRoomPopup} />
        )}

        {isOfficePopupOpen && officePopupData && (
          <OfficeDetailsPopup
            officeData={officePopupData}
            handleClose={handleCloseOfficePopup}
          />
        )}

        {/* User Data Popup */}
        {isUserDataPopupOpen && <UserDataPopup onClose={handleCloseUserDataPopup} />}

        {/* Vacant Form Popup */}
        {isVacantFormPopupOpen && <VacantFormPopup onClose={handleCloseVacantFormPopup} />}
      </div>

      <DashboardHeader />
    </div>
  );
};

export default Dashboard;
