
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Login.css';

const Login = () => {
    const [showAdminPopup, setShowAdminPopup] = useState(false);
    const [showStaffPopup, setShowStaffPopup] = useState(false);
  
    const [username, setUsername] = useState('');
   
   
   
    const [showUserPopup, setShowUserPopup] = useState(false);
    const [email, setEmail] = useState('');
    const [otp, setOtp] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [step, setStep] = useState(1); // Track the current step (email, otp, password)
    const navigate = useNavigate();

    const toggleAdminPopup = () => setShowAdminPopup(!showAdminPopup);
    const toggleStaffPopup = () => setShowStaffPopup(!showStaffPopup);
    const toggleUserPopup = () => setShowUserPopup(!showUserPopup);

    const handleAdminLogin = async (e) => {
        e.preventDefault();
        setErrorMessage('');
    
        try {
            const response = await fetch('/login', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ username, password }),
            });
    
            const data = await response.json();
    
            if (response.ok) {
                // Store the token and email in session storage
                sessionStorage.setItem('token', data.token); // Assuming 'data.token' contains the token from the backend
                sessionStorage.setItem('EmailId', data.user.email); // Assuming 'data.email' contains the EmailId from the backend
    
                // Navigate to dashboard
                navigate('/dashboard'); // Adjust the route as needed
            } else {
                setErrorMessage(data.message || 'Login failed. Please check your credentials.');
            }
        } catch (error) {
            setErrorMessage('An error occurred. Please try again.');
        }
    };
    

    const handleStaffLogin = async (e) => {
        e.preventDefault(); // Ensure form submission is prevented
        setErrorMessage('');
        try {
            const response = await fetch('/staff-login', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ username, password }), // Ensure username and password are sent correctly
            });

            const data = await response.json();
            if (response.ok) {
                // Store the token in session storage
                sessionStorage.setItem('token', data.token); // Adjust according to the actual property name in your response
                navigate('/dashboard'); // Adjust the route as needed
            } else {
                setErrorMessage(data.message || 'Login failed. Please check your credentials.');
            }
        } catch (error) {
            setErrorMessage('An error occurred. Please try again.');
        }
    };

    // Handle user login submission
    const handleUserLogin = async (e) => {
        e.preventDefault();
        setErrorMessage('');

        if (step === 1) {
            // Step 1: Send OTP to email
            try {
                const response = await fetch('/send-otp', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ email }),
                });

                const data = await response.json();

                if (response.ok) {
                    setStep(2); // Proceed to OTP step
                } else {
                    setErrorMessage(data.message || 'Error sending OTP.');
                }
            } catch (error) {
                setErrorMessage('An error occurred while sending OTP. Please try again.');
            }
        } else if (step === 2) {
            // Step 2: Verify OTP
            try {
                const response = await fetch('/verify-otp', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ email, otp }),
                });

                const data = await response.json();

                if (response.ok) {
                    setStep(3); // Proceed to password setup
                } else {
                    setErrorMessage(data.message || 'Invalid OTP.');
                }
            } catch (error) {
                setErrorMessage('An error occurred while verifying OTP. Please try again.');
            }
        } else if (step === 3) {
            // Step 3: Set password and complete registration/login
            try {
                const response = await fetch('/set-password', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ email, password }),
                });

                const data = await response.json();

                if (response.ok) {
                    // Proceed with the login after setting password
                    const loginResponse = await fetch('/outsider-login', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({ username: email, password }),
                    });

                    const loginData = await loginResponse.json();
                    if (loginResponse.ok) {
                        sessionStorage.setItem('token', loginData.token);
                        navigate('/dashboard');
                    } else {
                        setErrorMessage(loginData.message || 'Login failed. Please check your credentials.');
                    }
                } else {
                    setErrorMessage(data.message || 'Error setting password.');
                }
            } catch (error) {
                setErrorMessage('An error occurred while setting the password. Please try again.');
            }
        }
    };

        

    return (
        <div className="login-page">
            <div className="left-section"></div>
            <div className="right-section">
                <div className="login-container">
                    <h2>Login to Insight Space</h2>
                    <div className="login-buttons">
                        <button onClick={toggleAdminPopup} className="btn-primary">Login as Admin</button>
                        <button onClick={toggleStaffPopup} className="btn-primary">Login as Staff</button>
                        <button onClick={toggleUserPopup} className="btn-primary">Login as User</button>
                    </div>

                    {/* Admin Login Popup */}
                    {showAdminPopup && (
                        <div className="admin-popup">
                            <div className="popup-content">
                                <h3>Admin Login</h3>
                                <form className="admin-login-form" onSubmit={handleAdminLogin}>
                                    <input 
                                        type="text" 
                                        placeholder="Username" 
                                        value={username} 
                                        onChange={(e) => setUsername(e.target.value)} 
                                        required 
                                    />
                                    <input 
                                        type="password" 
                                        placeholder="Password" 
                                        value={password} 
                                        onChange={(e) => setPassword(e.target.value)} 
                                        required 
                                    />
                                    <button type="submit" className="btn-primary">Login</button>
                                </form>
                                {errorMessage && <p className="error-message">{errorMessage}</p>}
                                <button onClick={toggleAdminPopup} className="close-popup">Close</button>
                            </div>
                        </div>
                    )}

                    {/* Staff Login Popup */}
                    {showStaffPopup && (
                        <div className="staff-popup">
                            <div className="popup-content">
                                <h3>Staff Login</h3>
                                <form className="staff-login-form" onSubmit={handleStaffLogin}>
                                    <input 
                                        type="text" 
                                        placeholder="Username" 
                                        value={username} 
                                        onChange={(e) => setUsername(e.target.value)} 
                                        required 
                                    />
                                    <input 
                                        type="password" 
                                        placeholder="Password" 
                                        value={password} 
                                        onChange={(e) => setPassword(e.target.value)} 
                                        required 
                                    />
                                    <button type="submit" className="btn-primary">Login</button>
                                </form>
                                {errorMessage && <p className="error-message">{errorMessage}</p>}
                                <button onClick={toggleStaffPopup} className="close-popup">Close</button>
                            </div>
                        </div>
                    )}

                     {/* User Login Popup */}
                     {showUserPopup && (
                        <div className="user-popup">
                            <div className="popup-content">
                                <h3>User Login</h3>
                                <form className="user-login-form" onSubmit={handleUserLogin}>
                                    {step === 1 && (
                                        <div>
                                            <input
                                                type="email"
                                                placeholder="Enter your Email ID"
                                                value={email}
                                                onChange={(e) => setEmail(e.target.value)}
                                                required
                                            />
                                            <button type="submit" className="btn-primary">Send OTP</button>
                                        </div>
                                    )}

                                    {step === 2 && (
                                        <div>
                                            <input
                                                type="text"
                                                placeholder="Enter OTP"
                                                value={otp}
                                                onChange={(e) => setOtp(e.target.value)}
                                                required
                                            />
                                            <button type="submit" className="btn-primary">Verify OTP</button>
                                        </div>
                                    )}

                                    {step === 3 && (
                                        <div>
                                            <input
                                                type="password"
                                                placeholder="Set Password"
                                                value={password}
                                                onChange={(e) => setPassword(e.target.value)}
                                                required
                                            />
                                            <button type="submit" className="btn-primary">Set Password</button>
                                        </div>
                                    )}

                                    {errorMessage && <p className="error-message">{errorMessage}</p>}
                                </form>
                                <button onClick={() => setShowUserPopup(false)} className="close-popup">Close</button>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Login;