import React, { useState, useEffect } from 'react';
import './TenantDetailsPopup.css';

const TenantDetailsPopup = ({ roomCode, handleClose }) => {
    const [tenantData, setTenantData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchTenantData = async () => {
            const roomCodeInt =
                typeof roomCode === 'number' ? roomCode :
                (typeof roomCode === 'string' && !isNaN(roomCode)) ? parseInt(roomCode, 10) : null;

            console.log('Room Code to be sent:', roomCodeInt);

            if (roomCodeInt === null) {
                setError('Invalid room code provided.');
                setLoading(false);
                return;
            }

            try {
                const response = await fetch('/api/getTenantData', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ roomCode: roomCodeInt }), // Send roomCode as an integer
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                const data = await response.json();
                // Ensure that we're accessing the tenantData from the response
                if (data.success) {
                    setTenantData(data.tenantData);
                } else {
                    setError(data.message || 'No tenant data found.');
                }
            } catch (error) {
                console.error('Error fetching tenant data:', error);
                setError('Failed to load tenant data. Please try again later.');
            } finally {
                setLoading(false);
            }
        };

        fetchTenantData();
    }, [roomCode]);

    return (
        <div className="tenant-popup">
            <div className="tenant-popup-content">
                <h3>Tenant Details</h3>
                {loading && <p>Loading tenant data...</p>}
                {error && <p className="error-message">{error}</p>}
                {tenantData && (
                    <div>
                        <p><strong>Tenant Name:</strong> {tenantData.TenantName || "N/A"}</p>
                        <p><strong>Rental Period:</strong> {tenantData.RentalPeriod || "N/A"}</p>
                        <p><strong>Rent Status:</strong> {tenantData.RentStatus || "N/A"}</p>
                        <p><strong>Features Available:</strong> {tenantData.FeaturesAvailable || "N/A"}</p>
                    </div>
                )}
                <button onClick={handleClose} aria-label="Close">❌ Close</button>
            </div>
        </div>
    );
};

export default TenantDetailsPopup;
